import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/layout'
import Seo from '../components/seo'
import Content, { HTMLContent } from '../components/Content/Content'
import HeroImage from "../components/HeroImage/heroImage"
import Modal from '../components/Modal/Modal';
import FormService from '../components/FormService/FormService';
import SliderSmall from '../components/SliderSmall/SliderSmall';
import CtaBanner from '../components/CtaBanner/CtaBanner'

import "../assets/sass/theme/pages/service-child.scss"

class ServiceChildPageTemplate extends React.Component {
  state = {
    isOpen: false,
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    const { isOpen } = this.state;
    const {
      content,
      contentComponent,
      title,
      helmet,
      serviceimage,
      galleryImages,
    } = this.props;

    const ServiceChildContent = contentComponent || Content
    const str = title;
    const titleStrMod = str.replace(/[^A-Z0-9]/ig, "-").toLowerCase();
    console.log(title);
    // console.log(titleStrMod);
    //const url = typeof window !== 'undefined' ? window.location.href : '';
    return(
      <>
        <Seo
          title={title}
          description={`Specialists in ${title} nationwide. CNR Engineering offer a tailored solution with our wide range of vehicles and expertise, suitable for a variety of jobs.`}
        />
        <HeroImage title={title} subtitle="Service" image={serviceimage} position="center center" padding="150px 0" />
        <main>
          <div className="page-width">
            <div className="service-child">
              <div className="service__images">
                <SliderSmall images={galleryImages} />
              </div>
              <div className="service__content">
                <div className="service__content_inner">
                  <ServiceChildContent content={content} />
                  {
                    title === 'Mobile Metrology & Inspection'
                    ? <a href="https://cnrmetrology.co.uk/">Visit CNR Metrology website</a>
                    : <button className="button--brand button cta__button" onClick={this.toggleModal}>Get in touch</button>
                  }
                  <Modal isOpen={isOpen} service={title} toggleModal={this.toggleModal} children={<FormService title={title} formID={titleStrMod} />} />
                </div>
              </div>
            </div>
          </div>
        </main>
        <CtaBanner ctaText="Need More Information About This Service?" />
      </>
    )
  }
}

ServiceChildPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const ServiceChildPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <ServiceChildPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Our Service">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        serviceimage={post.frontmatter.serviceimage}
        galleryImages={post.frontmatter.galleryImages}
      />
    </Layout>
  )
}

ServiceChildPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ServiceChildPage

export const pageQuery = graphql`
  query ServiceChildPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        serviceimage
        description
        tags
        galleryImages
      }
    }
  }
`
